import Vue from 'vue';
import VueRouter from 'vue-router';
import parts from './guards/parts';
import loading from '@/mixins/modules/shared/loading';
import store from '@/store/index';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Homepage',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Homepage.vue'),
		meta: {
			bottomBarSelected: 1,
		},
	},
	{
		path: '/home',
		name: 'Home',
		component: () => import(/* webpackChunkName: "auth" */ '@/views/Home.vue'),
		meta: {
			onlyNoAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},

	{
		path: '/type',
		name: 'AccountType',
		component: () => import(/* webpackChunkName: "auth" */ '@/components/Auth/AccountType.vue'),
		meta: {
			onlyNoAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},

	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "auth" */ '@/components/Auth/Login.vue'),
		meta: {
			onlyNoAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/signup',
		name: 'Signup',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Auth/SignUp.vue'),
		meta: {
			onlyNoAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/mypreferences',
		name: 'MyPreferences',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/UserPreferences.vue'),
		meta: {
			requiresAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/forgotpassword',
		name: 'ForgotPassword',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Auth/ForgotPassword.vue'),
		meta: {
			onlyNoAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/checkemail',
		name: 'CheckEmail',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Auth/CheckEmail.vue'),
		meta: {
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/confirmCode',
		name: 'ConfirmCode',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Auth/ConfirmEmailCode.vue'),
		meta: {
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/storesignup',
		name: 'StoreSignup',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Auth/StoreSignUp.vue'),
		meta: {
			onlyNoAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/storeinfo',
		name: 'StoreInfo',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Auth/StoreInfo.vue'),
		meta: {
			requiresAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/reset-password',
		name: 'Resetpassword',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Auth/ResetPassword.vue'),
		meta: {
			onlyNoAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/reset-password-code',
		name: 'ResetpasswordCode',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Auth/ResetPasswordCode.vue'),
		meta: {
			onlyNoAuth: true,
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/settings',
		name: 'Settings',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Settings.vue'),
		meta: {
			requiresAuth: true,
			hideBottomBar: true,
			hideTopBarMobile: true,
		},
	},
	{
		path: '/changepassword',
		name: 'ChangePassword',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/ChangePassword.vue'),
		meta: {
			requiresAuth: true,
			hideBottomBar: true,
			hideTopBarMobile: true,
			hideTopBarDesktop: false,
			hideFooter: true,
		},
	},

	{
		path: '/edituserprofile',
		name: 'EditUserProfile',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/EditUserProfile.vue'),
		meta: {
			requiresAuth: true,
			bottomBarSelected: 5,
			hideTopBarMobile: true,
		},
	},
	{
		path: '/editstoreprofile',
		name: 'EditStoreProfile',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/EditStoreProfile.vue'),
		meta: {
			requiresAuth: true,
			bottomBarSelected: 5,
			hideTopBarMobile: true,
		},
	},
	{
		path: '/storeproducts',
		name: 'StoreProducts',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Store/StoreProducts.vue'),
		meta: {
			requiresAuth: true,
			bottomBarSelected: 2,
		},
	},

	{
		path: '/auth/email-confirmation',
		name: 'Activate',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Auth/ActivateAccount.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
			hideBottomBar: true,
		},
	},
	{
		path: '/userprofile',
		name: 'UserProfile',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/UserProfile.vue'),
		meta: {
			requiresAuth: true,
			bottomBarSelected: 5,
		},
	},
	{
		path: '/addproduct',
		name: 'AddProduct',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Store/AddProduct.vue'),
		meta: {
			requiresAuth: true,
			bottomBarSelected: 3,
		},
	},
	{
		path: '/storevouchers',
		name: 'StoreVouchers',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/StoreVouchers.vue'),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/store-orders',
		name: 'StoreOrders',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/StoreOrders.vue'),
		meta: {
			requiresAuth: true,
			bottomBarSelected: 3,
		},
	},
	{
		path: '/storeprofile/:id',
		name: 'StoreProfile',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/StoreProfile.vue'),
		meta: {
			bottomBarSelected: 5,
		},
	},
	{
		path: '/balance',
		name: 'Balance',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Balance.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
			hideBottomBar: true,
		},
	},
	{
		path: '/details/:id',
		name: 'ProductDetails',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/ProductDetails.vue'),
		meta: {
			hideTopBarMobile: true,
		},
	},
	{
		path: '/desktop-store-products',
		name: 'DesktopStoreProducts',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Desktop/Store/StoreProducts.vue'),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/desktop-add-product',
		name: 'DesktopAddProduct',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Desktop/Store/AddProduct.vue'),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/desktop-edit-product/:id',
		name: 'DesktopEditProduct',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Desktop/Store/EditProduct.vue'),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/editproduct/:id',
		name: 'EditProduct',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Store/EditProduct.vue'),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/store-success',
		name: 'StoreSuccess',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/StoreActivation'),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/cart',
		name: 'Cart',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Cart.vue'),
		meta: {
			bottomBarSelected: 3,
			requiresAuth: true,
		},
	},
	{
		path: '/choose-user-style',
		name: 'ChooseUserStyle',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/User/ChooseUserStyle.vue'),
		meta: {
			hideBottomBar: true,
			hideTopBar: true,
			hideTopBarMobile: true,
		},
	},
	{
		path: '/product-review',
		name: 'ProductReview',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Products/ProductReview.vue'),
		meta: {
			hideBottomBar: true,
			hideTopBar: true,
			hideTopBarMobile: true,
		},
		props: true,
	},
	{
		path: '/router',
		name: 'Router',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Router.vue'),
	},
	{
		path: '/filter',
		name: 'Filter',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Homepage/Filter.vue'),
		meta: {
			hideBottomBar: true,
			hideTopBarMobile: true,
		},
	},
	{
		path: '/wallet',
		name: 'Wallet',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Wallet.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
		},
	},

	{
		path: '/product-comment',
		name: 'ProductComment',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Products/ProductComments.vue'),
		meta: {
			hideBottomBar: true,
			hideTopBar: true,
			hideTopBarMobile: true,
		},
		props: true,
	},

	{
		path: '/checkout/cancel',
		name: 'CancelCheckout',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Cart/CancelCheckout.vue'),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/checkout/success',
		name: 'SuccessCheckout',
		component: () => import(/* webpackChunkName: "auth"  */ '@/components/Cart/SuccessCheckout.vue'),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/shipping',
		name: 'Shipping',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Shipping.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
			hideBottomBar: true,
		},
	},
	{
		path: '/order-detail/:id',
		name: 'OrderDetail',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/OrderDetail.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
			hideBottomBar: true,
		},
	},
	{
		path: '/transactions',
		name: 'Transactions',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/TransactionsHistory.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
			hideBottomBar: true,
		},
	},
	{
		path: '/tracking/:id/:tracking',
		name: 'Tracking',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Tracking.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
			hideBottomBar: true,
		},
	},
	{
		path: '/devolution/:id',
		name: 'Devolution',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/DevolutionDetail.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
			hideBottomBar: true,
		},
	},
	{
		path: '/marketing',
		name: 'Marketing',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Marketing.vue'),
		meta: {
			requiresAuth: true,
			bottomBarSelected: 4,
		},
	},
	{
		path: '/whishlist',
		name: 'Whishlist',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Whishlist.vue'),
		meta: {
			requiresAuth: true,
			bottomBarSelected: 4,
		},
	},

	{
		path: '/notifications',
		name: 'Notifications',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Notifications.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
		},
	},
	{
		path: '/interests',
		name: 'Interests',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Interests.vue'),
		meta: {
			requiresAuth: true,
			hideTopBarMobile: true,
		},
	},
	{
		path: '/stores-list',
		name: 'StoresList',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/StoresList'),
		meta: {
			bottomBarSelected: 2,
		},
	},
	{
		path: '/terms',
		name: 'Terms',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/Terms'),
		meta: {
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/terms-conditions',
		name: 'TermsConditions',
		component: () => import(/* webpackChunkName: "auth"  */ '@/views/FacebookTerms'),
		meta: {
			hideBottomBar: true,
			hideTopBarDesktop: true,
			hideTopBarMobile: true,
			hideFooter: true,
		},
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/',
		meta: {
			bottomBarSelected: 1,
		},
	},
];

const router = new VueRouter({
	mode: process.env.VUE_APP_ROUTER_MODE,
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if ((to.name == 'Homepage' && from.name == 'ProductDetails') || (to.name == 'Homepage' && from.name == 'ProductComment')) {
		store.commit('parts/setCanScroll', true);
	}

	if (from.name == 'Homepage' && to.name == 'StoreProfile') {
		store.commit('filter/setSavedFilterString', store.getters['filter/getFilterString']);
		store.commit('filter/setFilterString', '');
		store.commit('filter/setResetFilter', true);
		store.commit('filter/setSavedFilters', true);
	}

	if (from.name == 'StoreProfile' && to.name == 'Homepage') {
		store.commit('filter/setFilterString', store.getters['filter/getSavedFilterString']);
		store.commit('filter/setSavedFilters', true);
	}

	if (from.name == 'ProductDetails' && to.name == 'StoreProfile') {
		store.commit('filter/setResetFilter', true);
		store.commit('filter/setSavedFilters', true);
	}
	if (from.name == 'StoreProfile' && to.name == 'ProductDetails') {
		store.commit('filter/setSavedFilters', true);
	}

	loading.methods.setIsPageLoading(false);
	parts(to, from, next);

	next();
});

export default router;
