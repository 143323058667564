// initial state
const state = {
	isBottomBarVisible: false,
	bottomBarSelected: 1,
	isTopBarVisibleMobile: false,
	isTopBarVisibleDesktop: false,
	isPageLoading: false,
	hasAcceptedCookies: false,
	isFooterVisible: false,
	isMenuVisible: false,
	isNotificationVisible: false,
	scrollY: 0,
	canScroll: false,
	visitedProductId: null,
	pageRequested: 0,
	savedSort: null,
	savedMobileEmphasisProdcuts: null,
};

// getters
const getters = {
	isBottomBarVisible(state) {
		return state.isBottomBarVisible;
	},
	bottomBarSelected(state) {
		return state.bottomBarSelected;
	},
	isTopBarVisibleMobile(state) {
		return state.isTopBarVisibleMobile;
	},
	isTopBarVisibleDesktop(state) {
		return state.isTopBarVisibleDesktop;
	},
	isPageLoading(state) {
		return state.isPageLoading;
	},
	hasAcceptedCookies(state) {
		return state.hasAcceptedCookies;
	},
	isFooterVisible(state) {
		return state.isFooterVisible;
	},
	isMenuVisible(state) {
		return state.isMenuVisible;
	},
	isNotificationVisible(state) {
		return state.isNotificationVisible;
	},
	getScrollY(state) {
		return state.scrollY;
	},
	canScroll(state) {
		return state.canScroll;
	},

	getVisitedProductId(state) {
		return state.visitedProductId;
	},

	getPageRequested(state) {
		return state.pageRequested;
	},

	getSavedSort(state) {
		return state.savedSort;
	},
	getSavedMobileEmphasisProdcuts(state) {
		return state.savedMobileEmphasisProdcuts;
	},
};

// mutations
const mutations = {
	setIsBottomBarVisible(state, bol) {
		state.isBottomBarVisible = bol;
	},
	setBottomBarSelected(state, num) {
		state.bottomBarSelected = num;
	},
	setIsTopBarVisibleMobile(state, bol) {
		state.isTopBarVisibleMobile = bol;
	},
	setIsTopBarVisibleDesktop(state, bol) {
		state.isTopBarVisibleDesktop = bol;
	},
	setIsPageLoading(state, bol) {
		state.isPageLoading = bol;
	},
	setHasAcceptedCookies(state, bol) {
		state.hasAcceptedCookies = bol;
	},
	setIsFooterVisible(state, bol) {
		state.isFooterVisible = bol;
	},
	setIsMenuVisible(state, bol) {
		state.isMenuVisible = bol;
	},
	setIsNotificationVisible(state, bol) {
		state.isNotificationVisible = bol;
	},
	setScrollY(state, y) {
		state.scrollY = y;
	},
	setCanScroll(state, bol) {
		state.canScroll = bol;
	},

	setVisitedProductId(state, id) {
		state.visitedProductId = id;
	},

	setPageRequested(state, num) {
		state.pageRequested = num;
	},
	setSavedSort(state, sort) {
		state.savedSort = sort;
	},

	setSavedMobileEmphasisProdcuts(state, sort) {
		state.savedMobileEmphasisProdcuts = sort;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
