<template>
	<div class="Components-Parts-TopBar">
		<nav class="navbar is-fixed-top is-hidden-desktop" role="navigation" aria-label="main navigation">
			<div class="navbar-item has-background-primary is-flex is-justify-content-center" v-if="getAccountType != 'store'">
				<h1 class="has-text-white has-text-weight-bold is-size-6">{{ t.bannerText }}</h1>
			</div>
			<section class="section py-0">
				<div class="container">
					<div class="navbar-brand">
						<div
							v-show="
								this.$route.name == 'UserProfile' ||
								this.$route.name == 'StoreProfile' ||
								this.$route.name == 'EditStoreProfile' ||
								this.$route.name == 'AddProduct' ||
								this.$route.name == 'EditProduct'
							"
							class="is-fixed-top mt-2 mr-1"
						>
							<b-icon @click.native="$router.back()" icon="chevron-left" class="mt-2" type="is-grey-darker" size="is-medium"> </b-icon>
						</div>
						<a class="navbar-item pl-0">
							<img
								v-if="
									this.$route.name !== 'UserProfile' &&
									this.$route.name !== 'StoreProfile' &&
									this.$route.name !== 'EditStoreProfile' &&
									this.$route.name !== 'AddProduct' &&
									this.$route.name !== 'EditProduct'
								"
								src="@/assets/logo.png"
								alt=""
							/>
							<span v-if="this.$route.name == 'UserProfile'" class="is-size-4">{{ t.myAccount }}</span>
							<span v-if="this.$route.name == 'StoreProfile'" class="is-size-4">{{ t.profile }}</span>
							<span v-if="this.$route.name == 'EditStoreProfile'" class="is-size-4">{{ t.profile }}</span>
							<span v-if="this.$route.name == 'AddProduct'" class="is-size-4">{{ t.product }}</span>
							<span v-if="this.$route.name == 'EditProduct'" class="is-size-4">{{ t.edirProduct }}</span>
						</a>
						<a class="navbar-item ml-auto" v-if="getToken">
							<b-icon pack="rt" icon="notifications" size="is-size-4" type="is-grey" @click.native="goToPage('Notifications')"> </b-icon>
							<span class="badge" v-if="notifications.length > 0"> {{ notifications.length }}</span>
						</a>
						<a class="navbar-item" v-if="getAccountType == 'authenticated' && getToken">
							<b-icon @click.native="goToPage('Balance')" icon="currency-eur" size="is-size-4" type="is-grey"></b-icon>
						</a>
						<a class="navbar-item" v-if="getToken">
							<b-icon @click.native="addSidebarStatus(true)" pack="rt" icon="burguer" size="is-size-4" type="is-grey"></b-icon>
						</a>
						<a class="navbar-item mt-3" style="position: absolute; right: 0" v-if="!getToken">
							<p class="has-text-black" @click="goToPage('Home')">{{ t.login }}</p>
						</a>
					</div>
				</div>
			</section>
		</nav>
		<nav class="navbar is-fixed-top is-hidden-touch" :class="getAccountType != 'store' ? 'pt-0' : ''" style="display: block" role="navigation" aria-label="main navigation">
			<div class="navbar-item has-background-primary is-flex is-justify-content-center" v-if="getAccountType != 'store'">
				<h1 class="has-text-white has-text-weight-bold is-size-5">{{ t.bannerText }}</h1>
			</div>
			<div class="container">
				<div class="navbar-brand">
					<div class="navbar-item">
						<div class="field is-grouped">
							<figure class="image is-48x48 mr-20" style="cursor: pointer" @click="goToPage('Homepage')">
								<img src="@/assets/logo.png" alt="" />
							</figure>
							<b-field grouped class="navbar-item" v-if="getAccountType != 'store'">
								<b-input
									:placeholder="t.search"
									custom-class="has-background-white"
									class="has-border has-radius"
									icon="magnify"
									icon-clickable
									v-debounce:1s="search"
									v-model="searchString"
									icon-right="close-circle"
									icon-right-clickable
									@icon-right-click="clearSearch"
								>
								</b-input>
								<b-button v-if="getAccountType == 'store'" type="is-primary"><b-icon icon="filter" pack="rt"></b-icon></b-button>
							</b-field>
						</div>
					</div>
				</div>
				<div class="navbar-end">
					<div class="navbar-item">
						<div class="field is-grouped">
							<a v-if="getAccountType == 'authenticated' && getToken" @click="goToPage('Whishlist')" class="navbar-item mx-2">
								<b-icon icon="bookmark-outline" size="is-size-4" type="is-grey-darker"></b-icon>
								<span class="badge" v-if="getBookmark > 0" @click="goToPage('Whishlist')">{{ getBookmark }}</span>
							</a>

							<a v-if="getAccountType == 'authenticated' && getToken" @click="goToPage('Cart')" class="navbar-item mx-2">
								<b-icon pack="rt" icon="cart" size="is-size-4" type="is-grey-darker"></b-icon>
								<span class="badge" v-if="getCart && getCart.cartProducts" @click="goToPage('Cart')">{{ getTotal }}</span>
								<span class="badge" v-else>0</span>
							</a>

							<a v-if="getAccountType == 'store' && getToken" class="navbar-item ml-auto" @click="goToPage('Homepage')">
								<p class="has-text-grey-darker" @click="goToPage('Homepage')">{{ t.home }}</p>
							</a>
							<a v-if="getAccountType == 'store' && getToken" class="navbar-item ml-auto" @click="goToPage('Marketing')">
								<p class="has-text-grey-darker" @click="goToPage('Marketing')">{{ t.marketing }}</p>
							</a>

							<a @click="goToPage('DesktopStoreProducts')" v-if="getAccountType == 'store' && getToken" class="navbar-item ml-auto">
								<p class="has-text-grey-darker">{{ t.products }}</p>
							</a>
							<a v-if="getAccountType == 'store' && getToken" class="navbar-item mx-auto" @click="goToPage('StoreOrders')">
								<p class="has-text-grey-darker">{{ t.orders }}</p>
							</a>

							<a class="navbar-item mx-2" v-if="getToken">
								<b-icon pack="rt" icon="notifications" size="is-size-4" @click.native="openNotifications" type="is-grey-darker"></b-icon>
								<span class="badge" v-if="notifications.length > 0" @click="openNotifications"> {{ notifications.length }}</span>
								<Notifications :showNotifications="isNotificationVisible" v-if="getToken" />
							</a>
							<a v-if="getAccountType == 'authenticated' && getToken" class="navbar-item mx-2">
								<b-icon @click.native="goToPage('Balance')" icon="currency-eur" size="is-size-4" type="is-grey-darker"></b-icon>
							</a>
							<a class="navbar-item ml-auto" v-if="getToken">
								<b-icon @click.native="open" pack="rt" icon="arrow-down" size="is-size-6" type="is-dark"></b-icon>
								<figure @click="open" class="ml-2 image is-48x48 is-1by1 pt-0 has-radius has-text-centered">
									<b-icon v-if="!getProfilePicture" class="has-radius has-border-black" custom-size="is-size-3" icon="account-outline" type="is-black"> </b-icon>
									<img style="height: 46px" v-else class="has-radius k" :src="getProfilePicture" />
								</figure>
								<Menu :showMenu="isMenuVisible" />
							</a>
							<a class="navbar-item ml-auto" v-else>
								<p class="has-text-grey-darker" @click="goToPage('Home')">{{ t.login }}</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
	import parts from '@/mixins/modules/shared/parts';
	import Menu from '@/components/Parts/Menu';
	import Notifications from '@/components/Parts/Notifications';
	export default {
		name: 'TopBar',
		mixins: [parts],
		components: {
			Menu,
			Notifications,
		},
		data() {
			return {
				t: this.$t(`components.Desktop.Parts.TopBar`),
				showMenu: false,
				showNotifications: false,
				searchString: null,
			};
		},

		methods: {
			open() {
				this.setNotificationsVisible(false);
				this.setMenuVisible(!this.isMenuVisible);
			},
			openNotifications() {
				this.setMenuVisible(false);
				this.setNotificationsVisible(!this.isNotificationVisible);
				let ids = [];
				if (!this.isNotificationVisible && this.getNotifications.length > 0) {
					this.getNotifications.map((notification) => {
						ids.push(notification.id);
					});
					this.$socket.emit('notification_read_many', { ids: ids });
					this.setNotifications([]);
					console.log(ids);
				}
			},

			search() {
				this.setFilterString(this.searchString);
			},

			clearSearch() {
				this.searchString = '';
				this.setFilterString(this.searchString);
			},
		},

		computed: {
			notifications() {
				return this.getNotifications;
			},
		},

		mounted() {
			console.log(this.getCart);
			this.searchString = this.getFilterString;
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	nav {
		border-bottom: 2px solid $grey-lighter;
	}

	nav.is-hidden-desktop {
		min-height: $navbar-height-mobile !important;
		.navbar-brand {
			min-height: $navbar-height-mobile !important;
		}
		.navbar-item img {
			max-height: 2.5rem;
		}
	}

	nav.is-hidden-touch {
		padding-top: $topbar-padding-top;
		padding-bottom: $topbar-padding-bottom;

		img {
			max-height: 45px !important;
			object-fit: cover;
		}
	}

	.badge {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
	}
</style>
