<template>
	<b-loading :is-full-page="true" v-model="isPageLoading" :can-cancel="false">
		<div class="center-inside">
			<img width="140" class="loading-animation" src="@/assets/svg/logo.svg" />
		</div>
	</b-loading>
</template>

<script>
	export default {
		name: 'Loading',
	};
</script>

<style lang="scss" scoped></style>
