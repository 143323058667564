const state = {
	notifications: [],
};

const getters = {
	getNotifications(state) {
		return state.notifications;
	},
};

const mutations = {
	setNotifications(state, payload) {
		state.notifications = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};