import store from '@/store';
import axios from 'axios';
import user from '@/mixins/modules/auth/auth';

axios.defaults.timeout = 30000;
// MIDDLEWARE
axios.interceptors.response.use(
	function (response) {
		console.log(response);
		if (response.headers.authorization && response.headers) {
			store.commit('auth/setToken', response.headers.authorization);
		}
		return response;
	},
	function (error) {
		console.log(error.response);
		if (error?.response?.status == 401 || error?.response?.status == 403) {
			user.methods.onSessionExpired();
		} else console.log(error);
		return Promise.reject(error);
	}
);

const getHeaders = () => {
	let token = store.getters['auth/getToken'];
	if (token) {
		return {
			Authorization: `Bearer ${token}`,
		
		};
	}
};

export function post(uri, data, requiresAuth = true) {
	return axios.post(endpoint(uri), data, {
		...(requiresAuth && { headers: getHeaders() }),
	});
}

export function put(uri, data) {
	return axios.put(endpoint(uri), data, {
		headers: getHeaders(),
	});
}

export function remove(uri, data) {
	return axios.delete(endpoint(uri), {
		data,
		headers: getHeaders(),
	});
}

export function get(uri) {
	return axios.get(endpoint(uri), {
		headers: getHeaders(),
	});
}

export function endpoint(uri) {
	return process.env.VUE_APP_API_URL + uri;
}

export function formData(uri, data) {
	let config = {
		headers: getHeaders(),
	};
	config.headers['Content-Type'] = 'multipart/form-data';
	return axios.post(endpoint(uri), data, config);
}
export function formDataPut(uri, data) {
	let config = {
		headers: getHeaders(),
	};
	config.headers['Content-Type'] = 'multipart/form-data';
	return axios.put(endpoint(uri), data, config);
}
