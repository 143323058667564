// initial state
const state = {
	step: 0,
	info: {},
	showMessage: false,
};

// getters
const getters = {
	getStep(state, getters) {
		return state.step;
	},

	getRegisterInfo(state, getters) {
		return state.info;
	},

	getStoreMessage(state, getters) {
		return state.showMessage;
	},
};

// mutations
const mutations = {
	setStep(state, step) {
		state.step = step;
	},

	setInfo(state, data) {
		state.info = data;
	},

	setStoreMessage(state, data) {
		state.showMessage = data;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
