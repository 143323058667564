<template>
	<div class="menu">
		<div class="media">
			<div class="media-left"></div>
			<div class="media-content"></div>
			<div class="media-right">
				<div @click="addSidebarStatus(false)" class="close mt-8 mr-10 center-inside"><b-icon pack="rt" icon="close" size="is-size-4" type="is-white"></b-icon></div>
			</div>
		</div>

		<figure class="mx-auto image is-64x64 is-1by1 pt-0 has-radius has-border-black" :class="!getProfilePicture ? 'center-inside' : []">
			<b-icon v-if="!getProfilePicture" class="has-radius" custom-size="is-size-3" icon="account-outline" type="is-black"> </b-icon>
			<img v-else class="has-radius" :src="getProfilePicture" />
		</figure>

		<p class="has-text-centered mt-2">{{ getUser.name }}</p>
		<p v-show="address" style="font-size: 0.75rem" class="has-text-centered mt-2">{{ address }}</p>

		<div class="icons-container mt-8 ml-6" @click="profile">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="avatar" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4">{{ t.profile }}</span>
		</div>
		<br />

		<div class="icons-container mt-4 ml-6" @click="editProfile">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="avatar" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4">{{ t.edit }}</span>
		</div>
		<br v-show="getUserType == 'authenticated'" />

		<div class="icons-container mt-4 ml-6" v-show="getUserType == 'authenticated'" @click="goToPage('Interests')">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="view" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4">{{ t.interests }}</span>
		</div>
		<br v-show="getUserType == 'authenticated'" />

		<div class="icons-container mt-4 ml-6" @click="goToPage('Wallet')" v-show="getUserType == 'authenticated'">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="wallet" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4">{{ t.wallet }}</span>
		</div>
		<br />

		<div class="icons-container mt-4 ml-6" @click="goToPage('ChangePassword')" v-if="!getSocialLogin">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="lock" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4">{{ t.change }}</span>
		</div>
		<br v-if="!getSocialLogin" />

		<div class="icons-container mt-4 ml-6" @click="goToPage('Settings')">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="settings" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4">{{ t.settings }}</span>
		</div>
		<br />

		<div class="icons-container mt-4 ml-6" @click="goToPage('Transactions')">
			<div class="icon-box center-inside"><b-icon pack="rt" icon="transactions" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4">{{ t.transactions }}</span>
		</div>

		<p @click="logout" class="has-text-centered has-text-red mt-6"><b-icon pack="rt" icon="logout" size="is-size-7" type="is-red"></b-icon>{{ t.logout }}</p>
	</div>
</template>

<script>
	import parts from '@/mixins/modules/shared/parts';
	export default {
		data() {
			return {
				t: this.$t(`components.Parts.SideBar`),
				address: null,
			};
		},
		mixins: [parts],
		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},
		methods: {
			logout() {
				this.$socket.disconnect();
				this.onSessionExpired();
				this.$router.go()
				
			},

			profile() {
				if (this.getUserType == 'store') {
					this.$router.push({ name: 'StoreProfile', params: { id: this.getUser.store.id } });
					this.addSidebarStatus(false);
				} else this.goToPage('UserProfile');
			},

			editProfile() {
				if (this.getUserType == 'store') {
					this.goToPage('EditStoreProfile');
				} else this.goToPage('EditUserProfile');
			},
		},

		mounted() {
			if (this.getUser.location_municipality && this.getUser.location_country) {
				this.address = this.getUser.location_municipality + ', ' + this.getUser.location_country;
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.menu {
		background-color: white;
		height: 100vh;
		width: 308px;
		top: 0;
		z-index: 31;
		position: fixed;
		border-bottom-right-radius: 76px;
		border-top-right-radius: 42px;
		box-shadow: 3px 1px 14px 0px #515151;
		top: 0;
	}

	.close {
		width: 40px;
		height: 40px;
		background-color: $primary;
		float: right;
		border-radius: 10px;

		@include until($mobile-s) {
			width: 30px;
			height: 30px;
		}
	}

	.icon-box {
		background-color: $grey-darker;
		width: 42px;
		height: 42px;
		border-radius: 8px;
	}

	.icons-container {
		display: inline-flex;
	}

	span {
		display: inline-flex;
		align-items: center;
	}

	img {
		object-fit: cover;
	}
</style>
