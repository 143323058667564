import { getCart, createCart, addCartProduct, deleteCartProduct, updateCartProduct } from '@/api/cart';
import store from '@/store/index';

export default {
	computed: {
		getCart() {
			return store.getters['cart/getCart'];
		},
		getTotal() {
			return store.getters['cart/getTotal'];
		},
	},
	methods: {
		async getCartFromAPI(userId) {
			console.log(userId);
			return new Promise(async (resolve, reject) => {
				let cart = await getCart({
					filters: {
						$and: [
							{
								user: {
									id: userId,
								},
							},
							{
								open: true,
							},
						],
					},
					sort: ['id:desc'],
					populate:
						'cartProducts,cartProducts.product,cartProducts.product.pictures,cartProducts.productVariant,cartProducts.productSize, cartProducts.product.store, cartProducts.product.productSales',
				}).then(async (response) => {
					console.log(response);
					response.data.data.length > 0 ? this.setTotal(response.data.data[0].total) : this.setTotal(0);
					/* this.setTotal(response.data.data[0].total) */
					return response.data.data.length > 0 ? response.data.data[0] : null;
				});

				if (!cart) {
					cart = await createCart(
						{
							user: userId,
						},
						{ populate: 'cartProducts' }
					).then((response) => {
						return response.data.data;
					});
				}
				console.log(cart);

				this.setCart(cart);
				resolve();
			});
		},
		setCart(cart) {
			store.commit('cart/setCart', cart);
		},
		setTotal(total) {
			store.commit('cart/setTotal', total);
		},

		async addCartProduct(productId, variantId, sizeId, quantity) {
			let cartProduct = await addCartProduct(
				{
					product: productId,
					productVariant: variantId,
					productSize: sizeId,
					quantity: quantity,
					cart: this.getCart.id,
				},
				{
					populate: 'product,productVariant,productSize, cartProducts.product.store',
				}
			).then((response) => {
				console.log(response);
				this.setTotal(response.data.total);
				return response.data.data;
			});
			let cart = Object.clone(this.getCart);
			cart.cartProducts.push(cartProduct);
			this.setCart(cart);
		},
		async removeCartProduct(cartProductIds) {
			if (!Array.isArray(cartProductIds)) cartProductIds = [cartProductIds];
			return new Promise((resolve, reject) => {
				deleteCartProduct(cartProductIds)
					.then((response) => {
						let cart = Object.clone(this.getCart);
						cart.cartProducts = cart.cartProducts.filter((cartProduct) => !cartProductIds.includes(cartProduct.id));
						this.setCart(cart);
						this.getCartFromAPI(this.getUser.id);
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		async updateCartProduct(cartProductId, quantity) {
			return new Promise((resolve, reject) => {
				updateCartProduct(cartProductId, { quantity })
					.then((response) => {
						console.log(response);
						let cart = Object.clone(this.getCart);
						cart.cartProducts.forEach((cartProduct) => {
							if (cartProduct.id == cartProductId) cartProduct.quantity = quantity;
						});
						this.setCart(cart);
						this.getCartFromAPI(this.getUser.id);
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};
