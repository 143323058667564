const state = {
	IS_MOBILE: false,
	IS_MOBILE_NATIVE: false,
};

const initial = Object.clone(state);

const getters = {
	IS_MOBILE: (state) => {
		return state.IS_MOBILE;
	},
	IS_MOBILE_NATIVE: (state) => {
		return state.IS_MOBILE_NATIVE;
	},
};

const mutations = {
	SET_IS_MOBILE: (state, bol) => {
		state.IS_MOBILE = bol;
	},
	SET_IS_MOBILE_NATIVE: (state, bol) => {
		state.IS_MOBILE_NATIVE = bol;
	},
	resetStore(state) {
		state = Object.clone(initial);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
