<template>
	<div id="app" :class="getClasses" class="is-relative">
		<Loading />
		<BottomBar v-if="isBottomBarVisible" />
		<TopBar v-if="(isTopBarVisibleDesktop && !IS_MOBILE) || (isTopBarVisibleMobile && IS_MOBILE)" />
		<Sidebar v-if="getSidebarStatus" />
		<router-view :key="$route.fullPath" @click.native="closeMenus" />
		<Footer v-if="isFooterVisible" />
		<PWAPrompt
			copyClosePrompt="OK"
			copyTitle="Instalar App"
			copyBody="Este website tem a funcionalidade de APP. Siga os passos abaixo para instalar"
			copyShareButtonLabel="Clique no botão de 'Partilha'"
			copyAddHomeButtonLabel="Clique em 'Adicionar ao ecrã principal'"
		/>
	</div>
</template>

<script>
	import Sidebar from '@/components/Parts/SideBar.vue';
	import BottomBar from '@/components/Parts/BottomBar.vue';
	import TopBar from '@/components/Parts/TopBar.vue';
	import Footer from '@/components/Parts/Footer.vue';
	import Loading from '@/components/Parts/Loading.vue';
	import update from '@/mixins/modules/update';
	import parts from '@/mixins/modules/shared/parts';

	import browser from 'browser-detect';
	import icon from '../public/img/icons/mstile-150x150.png';

	import PWAPrompt from 'vue2-ios-pwa-prompt';

	export default {
		name: 'App',
		mixins: [update, parts],
		components: {
			Sidebar,
			BottomBar,
			TopBar,
			Footer,
			Loading,
			PWAPrompt,
		},

		data() {
			return {
				img: icon,
			};
		},
		created() {
			// console.log("CREATED");
			this.CHECK_DEVICE();
			window.addEventListener('resize', this.CHECK_DEVICE);
			if (!this.IS_MOBILE_NATIVE && !this.$store.getters['parts/hasAcceptedCookies']) this.showCookies();
		},
		methods: {
			CHECK_DEVICE() {
				this.SET_IS_MOBILE(browser().mobile || window.innerWidth <= 1023);
				this.SET_IS_MOBILE_NATIVE(process.env.VUE_APP_CAPACITOR_PLATFORM ? true : false);

				if (!this.IS_MOBILE && this.getSidebarStatus) this.addSidebarStatus(false);
			},
			showCookies() {
				let _this = this;
				this.$buefy.snackbar.open({
					message: this.$t('cookies'),
					position: 'is-bottom',
					type: 'is-primary',
					actionText: 'Ok',
					indefinite: true,
					onAction() {
						_this.$store.commit('parts/setHasAcceptedCookies', true);
					},
				});
			},

			closeMenus() {
				if (this.$route.name != 'ChooseUserStyle' && this.$route.name != 'Home' && this.$store.getters['desktopLoginStep/getStep'] != 3 && !this.IS_MOBILE) {
					parts.methods.setMenuVisible(false);
					parts.methods.setNotificationsVisible(false);
					parts.methods.addSidebarStatus(false);

					let ids = [];
					if (!this.isNotificationVisible && this.getNotifications.length > 0) {
						this.getNotifications.map((notification) => {
							ids.push(notification.id);
						});
						this.$socket.emit('notification_read_many', { ids: ids });

						this.setNotifications([]);
						console.log(ids);
					}
				}
			},
		},
		computed: {
			getClasses() {
				return {
					'has-navbar-fixed-top': (this.isTopBarVisibleDesktop && !this.IS_MOBILE) || (this.isTopBarVisibleMobile && this.IS_MOBILE),
					'has-navbar-fixed-bottom': this.isBottomBarVisible,
				};
			},
		},

		sockets: {
			connect: function () {
				console.log('socket connected');
				console.log(this.getToken);

				if (this.getToken) {
					this.$socket.emit('get_notifications', { token: this.getToken });
				}
			},

			notifications: function (notif) {
				console.log(notif);
				this.setNotifications(notif);
			},

			notification: function (notif) {
				console.log(notif);
				this.setNewNotification(notif);

				let notification = {
					events: {
						onclick: () => {
							if (this.IS_MOBILE) {
								this.$router.push('/notifications');
							} else this.$router.push('/homepage');
						},
					},
				};

				this.$notification.show(
					'Root',
					{
						body: notif.text,
						icon: this.img,
					},
					notification.events,
					{}
				);
			},
		},
	};
</script>

<style lang="scss">
	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overflow-x: hidden;
		// max-height: 100vh;
	}
</style>
