<template>
	<div v-if="isNotificationVisible" class="menu has-text-dark has-radius">
		<p class="has-text-centered has-text-grey-dark p-2 is-size-5">{{ t.notifications }}</p>

		<div class="p-3" v-if="todayUnread.length > 0">
			<p class="has-text-dark is-size-5 ml-4">{{ t.today }}</p>

			<div class="notification-card my-3" @click="goToUrl(unread.url, unread)" v-for="(unread, index) in todayUnread" :key="index">
				<div class="media">
					<div class="media-left mt-4 ml-4">
						<figure v-if="unread.type == 'profileData'" class="image is-64x64 root is-background-size-cover is-rounded pointer"></figure>
						<figure
							v-else-if="unread.image"
							class="image is-64x64 is-background-size-cover is-rounded pointer"
							:style="[{ 'background-image': `url(${unread.image.url})` }]"
						></figure>
					</div>
					<div class="media-content mt-7">
						<p class="has-text-grey-darker text">{{ unread.text }}</p>
						<p class="has-text-grey text">{{ unread.createdAt | momentToday }}</p>
					</div>
					<div class="media-right">
						<div class="badge"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-3">
			<p class="has-text-dark is-size-5 ml-4">{{ t.older }}</p>

			<div class="notification-card my-3" @click="goToUrl(unread.url, unread)" v-for="(unread, index) in pastUnread" :key="index">
				<div class="media">
					<div class="media-left mt-4 ml-4">
						<figure v-if="unread.type == 'profileData'" class="image is-64x64 root is-background-size-cover is-rounded pointer"></figure>
						<figure
							v-else-if="unread.image"
							class="image is-64x64 is-background-size-cover is-rounded pointer"
							:style="[{ 'background-image': `url(${unread.image.url})` }]"
						></figure>
					</div>
					<div class="media-content mt-7">
						<p class="has-text-grey-darker text">{{ unread.text }}</p>
						<p class="has-text-grey text">{{ unread.createdAt | moment }}</p>
					</div>
					<div class="media-right">
						<div class="badge"></div>
					</div>
				</div>
			</div>
			<div class="notification-card my-3" @click="goToUrl(unread.url, unread)" v-for="(unread, index) in olderNotifications" :key="index + 400">
				<div class="media">
					<div class="media-left mt-4 ml-4">
						<figure v-if="unread.type == 'profileData'" class="image is-64x64 root is-background-size-cover is-rounded pointer"></figure>
						<figure
							v-else-if="unread.image"
							class="image is-64x64 is-background-size-cover is-rounded pointer"
							:style="[{ 'background-image': `url(${unread.image.url})` }]"
						></figure>
					</div>
					<div class="media-content mt-7">
						<p class="has-text-grey-darker text">{{ unread.text }}</p>
						<p class="has-text-grey text">{{ unread.createdAt | moment }}</p>
					</div>
					<div class="media-right"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import { getNotifications } from '@/api/notifications';
	import parts from '@/mixins/modules/shared/parts';
	export default {
		mixins: [parts],

		data() {
			return {
				t: this.$t(`views.Notifications`),
				todayUnread: [],
				pastUnread: [],
				olderNotifications: [],
			};
		},
		props: {
			showNotifications: {
				type: Boolean,
				default: false,
			},
		},

		computed: {
			notifications() {
				return this.getNotifications;
			},
		},

		methods: {
			goToUrl(url, notif) {
				console.log(url.split('/'));
				this.setNotificationsVisible(false);
				let ids = [];
				if (!this.isNotificationVisible && this.getNotifications.length > 0) {
					this.getNotifications.map((notification) => {
						ids.push(notification.id);
					});
					this.$socket.emit('notification_read_many', { ids: ids });
					this.setNotifications([]);
				}
				if (notif.type == 'orderDelivered') {
					let id = url.split('/');
					this.$router.push({ name: 'ProductDetails', params: { id: id[2], tab: 1 } });
				} else this.$router.push({ path: url });
			},
		},

		watch: {
			showNotifications(newVal) {
				console.log(newVal);
				if (newVal) {
					let today = moment().format('DD-MM-yyyy');

					this.getNotifications.map((notification) => {
						if (today == moment(notification.createdAt).format('DD-MM-yyyy')) {
							console.log(notification.id);
							let check = this.todayUnread.filter((notif) => notif.id == notification.id);
							console.log(check);
							if (check.length == 0) {
								this.todayUnread.push(notification);
							}
						} else this.pastUnread.push(notification);
					});
					console.log(this.todayUnread);
					console.log(this.pastUnread);
				} else {
					this.todayUnread = [];
					this.pastUnread = [];
					getNotifications({
						sort: ['id:desc'],
						'pagination[pageSize]': 10,
						'filters[wasViewed]': true,
					}).then((response) => {
						console.log(response);
						this.olderNotifications = response.data.data;
					});
				}
			},
		},

		filters: {
			momentToday: function (date) {
				return moment(date).fromNow();
			},

			moment: function (date) {
				return moment(date).format('MMM DD, YYYY, H:mm');
			},
		},

		mounted() {
			let today = moment().format('DD-MM-yyyy');

			this.notifications.map((notification) => {
				if (today == moment(notification.createdAt).format('DD-MM-yyyy')) {
					this.todayUnread.push(notification);
				} else this.pastUnread.push(notification);
			});
			console.log(this.todayUnread);
			console.log(this.pastUnread);

			getNotifications({
				sort: ['id:desc'],
				'pagination[pageSize]': 10,
				'filters[wasViewed]': true,
			}).then((response) => {
				console.log(response);
				this.olderNotifications = response.data.data;
			});
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.menu {
		width: 460px;
		height: 800px;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: absolute;
		right: 0;
		top: 62px;
		z-index: 31;
		overflow: auto;
	}

	.notification-card {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 48px #0000000a;
		border-radius: 12px;
		width: 96%;
		height: 92px;
		display: flex;
		margin: 0 auto;
		position: relative;
	}

	.text {
		max-width: 100%;
	}

	.badge {
		background-color: #99cca8;
		border-radius: 9999px !important;
		color: white;
		font-size: 9px;
		line-height: 1;
		width: 16px;
		height: 16px;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;
		position: absolute;
		right: 10px;
		top: -5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.root {
		background-image: url('../../../public/img/icons/android-chrome-96x96.png');
	}
</style>
