import store from '@/store/index';
import { ToastProgrammatic as Toast } from 'buefy';
import router from '@/router/index';
import cart from '@/mixins/modules/cart';
import goToPage from '../shared/goToPage';
import { startPushedNotifications } from '@/mixins/modules/notifications';
import { Capacitor } from '@capacitor/core';

export default {
	mixins: [cart, goToPage],
	computed: {
		getUser() {
			return store.getters['auth/getUser'];
		},
		getToken() {
			return store.getters['auth/getToken'];
		},
		getProfilePicture() {
			let user = this.getUser;
			let accountType = this.getAccountType;
			if (accountType == 'store') {
				if (user?.store?.pictures[0]?.formats?.thumbnail?.url) return user.store.pictures[0].formats.thumbnail.url;
				if (user?.store?.pictures[0]?.url) return user.store.pictures[0].url;
				else return null;
			} else if (accountType == 'authenticated') {
				if (user?.picture?.formats?.thumbnail?.url) return user.picture.formats.thumbnail.url;
				if (user?.picture?.url) return user.picture.url;
				else return null;
			} else return null;
		},
		getAccountType() {
			return store.getters['auth/getAccountType'];
		},

		getRecoverEmail() {
			return store.getters['auth/getRecoverEmail'];
		},
		getPw() {
			return store.getters['auth/getPw'];
		},
		getSocialLogin() {
			return store.getters['auth/getSocialLogin'];
		},
		getIsLoginReset() {
			return store.getters['auth/getIsLoginReset'];
		},
	},
	methods: {
		addUser(user) {
			store.commit('auth/addUser', user);
		},
		addToken(token) {
			store.commit('auth/setToken', token);
		},

		addAccount(type) {
			store.commit('auth/setAccountType', type);
		},
		onSessionExpired() {
			/* Toast.open({
				message: 'Sessão expirada, repita o processo!',
				type: 'is-danger',
				position: 'is-bottom',
			}); */
			store.commit('auth/resetStore');
			store.commit('auth/setToken', null);
			store.commit('auth/addUser', null);
			store.commit('auth/setSocialLogin', false);
			store.commit('auth/setAccountType', null);
			store.commit('filter/setCategory', []);
			store.commit('filter/setSize', []);
			store.commit('filter/setNumbers', []);
			store.commit('filter/setColor', []);
			store.commit('filter/setBrand', []);
			store.commit('filter/setOnlySale', null);
			store.commit('filter/setMainCategory', []);
			store.commit('filter/setFilterString', null);
			store.commit('cart/setCart', null);
			store.commit('shipping/setCameFromCard', false);
			store.commit('auth/setIsLoginReset', false);

			localStorage.removeItem('ROOT');
			goToPage.methods.goToPage('Homepage');
		},

		async onLogin(response) {
			this.addToken(response.data.jwt);
			this.addUser(response.data.user);
			this.addAccount(response.data.user.role.type);
			this.setIsLoginReset(true)
			this.setMainCategorySelected([]);
			if (Capacitor.isPluginAvailable('PushNotifications')) {
				startPushedNotifications();
			}

			if (this.getAccountType == 'authenticated') {
				await this.getCartFromAPI(this.getUser.id);
			}
			this.$socket.connect();
		},

		addRecoverEmail(email) {
			store.commit('auth/setRecoverEmail', email);
		},

		updateStore(storeData) {
			store.commit('auth/updateStore', storeData);
		},

		addPw(val) {
			store.commit('auth/setPw', val);
		},

		setSocialLogin(bol) {
			store.commit('auth/setSocialLogin', bol);
		},

		setIsLoginReset(bol) {
			store.commit('auth/setIsLoginReset', bol);
		}
	},
};
