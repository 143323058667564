import store from '@/store/index';

export default {
	computed: {
		isBottomBarVisible() {
			return store.getters['parts/isBottomBarVisible'];
		},
		bottomBarSelected() {
			return store.getters['parts/bottomBarSelected'];
		},
		getSidebarStatus() {
			return store.getters['sidebar/getSidebarStatus'];
		},
		isTopBarVisibleDesktop() {
			return store.getters['parts/isTopBarVisibleDesktop'];
		},
		isTopBarVisibleMobile() {
			return store.getters['parts/isTopBarVisibleMobile'];
		},
		isFooterVisible() {
			let isFooterVisible = store.getters['parts/isFooterVisible'];
			return isFooterVisible && !this.IS_MOBILE;
		},
		isMenuVisible() {
			return store.getters['parts/isMenuVisible'];
		},
		isNotificationVisible() {
			return store.getters['parts/isNotificationVisible'];
		},
		getScrollY() {
			return store.getters['parts/getScrollY'];
		},
		canScroll() {
			return store.getters['parts/canScroll'];
		},

		getVisitedProductId() {
			return store.getters['parts/getVisitedProductId'];
		},
		getPageRequested() {
			return store.getters['parts/getPageRequested'];
		},

		getSavedSort() {
			return store.getters['parts/getSavedSort'];
		},

		getSavedMobileEmphasisProdcuts() {
			return store.getters['parts/getSavedMobileEmphasisProdcuts'];
		},
	},
	methods: {
		setIsBottomBarVisible(bol) {
			store.commit('parts/setIsBottomBarVisible', bol);
		},
		setBottomBarSelected(num) {
			store.commit('parts/setBottomBarSelected', num);
		},
		addSidebarStatus(boolean) {
			store.commit('sidebar/setSidebarStatus', boolean);
		},
		setIsTopBarVisibleDesktop(bol) {
			store.commit('parts/setIsTopBarVisibleDesktop', bol);
		},
		setIsTopBarVisibleMobile(bol) {
			store.commit('parts/setIsTopBarVisibleMobile', bol);
		},
		setIsFooterVisible(bol) {
			store.commit('parts/setIsFooterVisible', bol);
		},

		setMenuVisible(bol) {
			store.commit('parts/setIsMenuVisible', bol);
		},
		setNotificationsVisible(bol) {
			store.commit('parts/setIsNotificationVisible', bol);
		},
		setScrollY(y) {
			store.commit('parts/setScrollY', y);
		},
		setCanScroll(bol) {
			store.commit('parts/setCanScroll', bol);
		},
		setVisitedProductId(id) {
			store.commit('parts/setVisitedProductId', id);
		},
		setPageRequested(page) {
			store.commit('parts/setPageRequested', page);
		},
		setSavedSort(sort) {
			store.commit('parts/setSavedSort', sort);
		},
		setSavedMobileEmphasisProdcuts(sort) {
			store.commit('parts/setSavedMobileEmphasisProdcuts', sort);
		},
	},
};
