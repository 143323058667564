// initial state
const state = {
	isOpen: false,
};

// getters
const getters = {
	getSidebarStatus(state, getters) {
		return state.isOpen;
	},
};

// mutations
const mutations = {
	setSidebarStatus(state, boolean) {
		state.isOpen = boolean;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
