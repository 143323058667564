const state = {
	shippingType: null,
	address: null,
	zipCode: null,
	city: null,
	country: null,
	billingAddress: null,
	billingZipCode: null,
	billingCity: null,
	billingCountry: null,
	extraDiscount: 0,
	discountCode: null,
	pickUpId: null,
	useDeliveryAddress: false,
	cameFromCard: false,
	paymentType: null,
	nif: null,
	company: null,
	phone: null,
};

const getters = {
	getShippingType(state) {
		return state.shippingType;
	},
	getAddress(state) {
		return state.address;
	},
	getZipCode(state) {
		return state.zipCode;
	},
	getCity(state) {
		return state.city;
	},
	getCountry(state) {
		return state.country;
	},
	getBillingAddress(state) {
		return state.billingAddress;
	},
	getBillingZipCode(state) {
		return state.billingZipCode;
	},
	getBillingCity(state) {
		return state.billingCity;
	},
	getBillingCountry(state) {
		return state.billingCountry;
	},
	getExtraDiscount(state) {
		return state.extraDiscount;
	},
	getDiscountMoney(state) {
		return state.discountMoney;
	},
	getDiscountCode(state) {
		return state.discountCode;
	},
	getPickupId(state) {
		return state.pickUpId;
	},
	getUseDeliveryAddress(state) {
		return state.useDeliveryAddress;
	},
	getCameFromCard(state) {
		return state.cameFromCard;
	},
	getPaymentType(state) {
		return state.paymentType;
	},
	getNif(state) {
		return state.nif;
	},
	getCompany(state) {
		return state.company;
	},
	getPhone(state) {
		return state.phone;
	},
};

const mutations = {
	setShippingType(state, payload) {
		state.shippingType = payload;
	},
	setAddress(state, payload) {
		state.address = payload;
	},
	setZipCode(state, payload) {
		state.zipCode = payload;
	},
	setCity(state, payload) {
		state.city = payload;
	},
	setCountry(state, payload) {
		state.country = payload;
	},
	setBillingAddress(state, payload) {
		state.billingAddress = payload;
	},
	setBillingZipCode(state, payload) {
		state.billingZipCode = payload;
	},
	setBillingCity(state, payload) {
		state.billingCity = payload;
	},
	setBillingCountry(state, payload) {
		state.billingCountry = payload;
	},
	setExtraDiscount(state, payload) {
		state.extraDiscount = payload;
	},
	setDiscountCode(state, payload) {
		state.discountCode = payload;
	},
	setPickUpId(state, payload) {
		state.pickUpId = payload;
	},
	setUseDeliveryAddress(state, payload) {
		state.useDeliveryAddress = payload;
	},
	setCameFromCard(state, payload) {
		state.cameFromCard = payload;
	},

	setPaymentType(state, payload) {
		state.paymentType = payload;
	},
	setNif(state, payload) {
		state.nif = payload;
	},
	setCompany(state, payload) {
		state.company = payload;
	},
	setPhone(state, payload) {
		state.phone = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
