import Vue from 'vue';
import goToPage from './modules/shared/goToPage';
import auth from './modules/auth/auth';
import mobile from './modules/shared/mobile';
import loading from './modules/shared/loading';
import filter from './modules/filter';
import bookmark from './modules/bookmark';
import notifications from './modules/notifications';
import shipping from './modules/shipping';

let mixins = [goToPage, auth, mobile, loading, filter, bookmark, notifications, shipping];

for (let mixin of mixins) Vue.mixin(mixin);
