import store from '@/store/index';
import { addToken } from '@/api/notifications';
import { PushNotifications } from '@capacitor/push-notifications';

export default {
	computed: {
		getNotifications() {
			return store.getters['notifications/getNotifications'];
		},
	},
	methods: {
		setNotifications(array) {
			store.commit('notifications/setNotifications', array);
		},

		setNewNotification(newNotif) {
			let array = this.getNotifications;
			array.push(newNotif);
			store.commit('notifications/setNotifications', array);
		},
	},
};

export function startPushedNotifications() {
	PushNotifications.requestPermissions().then((result) => {
		if (result.receive === 'granted') {
			PushNotifications.register();
		}
	});

	PushNotifications.addListener('registration', async (token) => {
		await addToken(token.value);
	});

	PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
		if (notification.actionId == 'tap') {
			let url = notification.notification.data.url;
			VueInstance.$router.push(url);
		}
	});
}
