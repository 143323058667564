import store from '@/store/index';

export default {
	computed: {
		isPageLoading() {
			return store.getters['parts/isPageLoading'];
		},
	},
	methods: {
		setIsPageLoading(bol) {
			store.commit('parts/setIsPageLoading', bol);
		},
	},
};
