const state = {
	categorySelected: [],
	sizeSelected: [],
	numbers: [0, 500],
	colorSelected: [],
	brandSelected: [],
	onlySale: null,
	mainCategorySelected: [],
	filterString: null,
	stores: [],
	savedFilterString: null,
	filterKey: 0,
	resetFilter: false,
	savedFilters: true,
	savedCategorySelected: [],
	savedSizeSelected: [],
	savedNumbers: [0, 500],
	savedColorSelected: [],
	savedBrandSelected: [],
	savedOnlySale: null,
	savedStores: [],
};

const getters = {
	getCategorySelected(state) {
		return state.categorySelected;
	},

	getSizeSelected(state) {
		return state.sizeSelected;
	},

	getNumbers(state) {
		return state.numbers;
	},

	getColorSelected(state) {
		return state.colorSelected;
	},
	getBrandSelected(state) {
		return state.brandSelected;
	},
	getOnlySale(state) {
		return state.onlySale;
	},
	getMainCategorySelected(state) {
		return state.mainCategorySelected;
	},
	getFilterString(state) {
		return state.filterString;
	},
	getFilterStores(state) {
		return state.stores;
	},
	getSavedFilterString(state) {
		return state.savedFilterString;
	},
	getResetFilter(state) {
		return state.resetFilter;
	},
	getSavedFilters(state) {
		return state.savedFilters;
	},
	getSavedCategorySelected(state) {
		return state.savedCategorySelected;
	},
	getSavedSizeSelected(state) {
		return state.savedSizeSelected;
	},
	getSavedNumbers(state) {
		return state.savedNumbers;
	},
	getSavedColorSelected(state) {
		return state.savedColorSelected;
	},
	getSavedBrandSelected(state) {
		return state.savedBrandSelected;
	},
	getSavedOnlySale(state) {
		return state.savedOnlySale;
	},
	getSavedStores(state) {
		return state.savedStores;
	},
};

const mutations = {
	setCategory(state, payload) {
		state.categorySelected = payload;
	},
	setSize(state, payload) {
		state.sizeSelected = payload;
	},
	setNumbers(state, payload) {
		state.numbers = payload;
	},
	setColor(state, payload) {
		state.colorSelected = payload;
	},
	setBrand(state, payload) {
		state.brandSelected = payload;
	},
	setOnlySale(state, payload) {
		state.onlySale = payload;
	},
	setMainCategory(state, payload) {
		state.mainCategorySelected = payload;
	},
	setFilterString(state, payload) {
		state.filterString = payload;
	},
	setFilterStores(state, payload) {
		state.stores = payload;
	},
	setSavedFilterString(state, payload) {
		state.savedFilterString = payload;
	},
	setResetFilter(state, payload) {
		state.resetFilter = payload;
	},
	setSavedFilters(state, payload) {
		state.savedFilters = payload;
	},
	setSavedCategorySelected(state, payload) {
		state.savedCategorySelected = payload;
	},
	setSavedSizeSelected(state, payload) {
		state.savedSizeSelected = payload;
	},
	setSavedNumbers(state, payload) {
		state.savedNumbers = payload;
	},
	setSavedColorSelected(state, payload) {
		state.savedColorSelected = payload;
	},
	setSavedBrandSelected(state, payload) {
		state.savedBrandSelected = payload;
	},
	setSavedOnlySale(state, payload) {
		state.savedOnlySale = payload;
	},
	setSavedStores(state, payload) {
		state.savedStores = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
