import { get, post } from '@/utils/http';
const queryString = require('qs');

export function getNotifications(query = {}) {
	let _query = queryString.stringify(query);
	return get('/api/notifications?populate=image&' + _query);
}

export function addToken(token) {
	return post('/api/firebase-messaging/', { token });
}
