import { get, post, put, remove } from '@/utils/http';
const queryString = require('qs');

export function getCart(query = {}) {
	let _query = queryString.stringify(query);
	return get(`/api/carts?${_query}`);
}

export function createCart(data, query = {}) {
	let _query = queryString.stringify(query);
	return post(`/api/carts?${_query}`, { data });
}

export function updateCart(id, data, query = {}) {
	let _query = queryString.stringify(query);
	return put(`/api/carts/${id}?${_query}`, { data });
}

export function addCartProduct(data, query = {}) {
	let _query = queryString.stringify(query);
	return post(`/api/cart-products?${_query}`, { data });
}

export function updateCartProduct(id, data, query = {}) {
	let _query = queryString.stringify(query);
	return put(`/api/cart-products/${id}?${_query}`, { data });
}

export function deleteCartProduct(cartProducts, query = {}) {
	let _query = queryString.stringify(query);
	return post(`/api/cart-product/delete-many/?${_query}`, {
		data: {
			cartProducts,
		},
	});
}

export function StripeCheckout(data) {
	return post(`/api/stripe/checkout`, data);
}

export function cancelStripeCheckout(id) {
	return remove(`/api/stripe/checkout?checkout_session=` + id);
}

export function getShippingRates() {
	return get('/api/shipping-rates');
}

export function getVariantById(id) {
	return get('/api/product-variants/' + id + '?populate=color');
}
export function getSizeById(id) {
	return get('/api/product-sizes/' + id + '?populate=size');
}
