import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import auth from './modules/auth';
import preferencesStep from './modules/preferencesStep';
import sidebar from './modules/sidebar';
import desktopLoginStep from './modules/desktopLoginStep';
import parts from './modules/parts';
import mobile from './modules/mobile';
import cart from './modules/cart';
import filter from './modules/filter';
import bookmark from './modules/bookmark';
import notifications from './modules/notifications';
import shipping from './modules/shipping';

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	key: 'ROOT',
});

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: { auth, preferencesStep, sidebar, desktopLoginStep, parts, mobile, cart, filter, bookmark, notifications, shipping },
	plugins: [vuexLocal.plugin],
});
