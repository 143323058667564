<template>
	<div class="navigation is-hidden-desktop">
		<ul>
			<li class="list">
				<a @click="goToPage('Homepage')" :class="isSelected(1)">
					<b-icon class="icon" custom-size="mdi-20px" icon="home" pack="rt"></b-icon>
				</a>
			</li>
			<li class="list">
				<a v-if="getUserType == 'store' && getToken" @click="goToPage('StoreProducts')" :class="isSelected(2)">
					<b-icon class="icon" custom-size="mdi-20px" icon="package" pack="rt"></b-icon>
				</a>
				<a v-else :class="isSelected(2)" @click="goToPage('StoresList')">
					<b-icon class="icon" custom-size="mdi-20px" icon="search" pack="rt"></b-icon>
				</a>
			</li>
			<li class="list float">
				<a v-if="getUserType == 'store' && getToken" @click="goToPage('StoreOrders')" :class="isSelected(3)">
					<b-icon class="icon" custom-size="mdi-20px" icon="cart" pack="rt"></b-icon>
				</a>
				<a v-else @click="goToCart" :class="isSelected(3)">
					<b-icon class="icon" custom-size="mdi-20px" icon="cart" pack="rt"></b-icon>
				</a>
			</li>
			<li class="list">
				<a v-if="getUserType == 'store' && getToken" :class="isSelected(4)" @click="goToPage('Marketing')">
					<b-icon class="icon" custom-size="mdi-20px" icon="share" pack="rt"></b-icon>
				</a>
				<a v-else :class="isSelected(4)">
					<b-icon @click.native="goToWishlist" class="icon" custom-size="mdi-20px" icon="bookmark-outline"></b-icon>
				</a>
			</li>
			<li class="list">
				<a @click="goToProfile" :class="isSelected(5)">
					<b-icon class="icon" custom-size="mdi-20px" icon="avatar" pack="rt"></b-icon>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
	import parts from '@/mixins/modules/shared/parts';
	export default {
		name: 'BottomBar',
		mixins: [parts],
		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},
		},
		methods: {
			isSelected(nav) {
				console.log(this.bottomBarSelected == nav);
				if (this.bottomBarSelected == nav) return 'has-text-primary';
				return 'has-text-grey';
			},

			goToProfile() {
				console.log(this.getUser);
				if (this.getToken) {
					if (this.getUser.store) {
						this.$router.push({ name: 'StoreProfile', params: { id: this.getUser.store.id } });
					} else this.goToPage('UserProfile');
				} else this.goToPage('Home');
			},

			goToWishlist() {
				if (this.getToken) {
					this.goToPage('Whishlist');
				} else this.goToPage('Home');
			},
			goToCart() {
				if (this.getToken) {
					this.goToPage('Cart');
				} else this.goToPage('Home');
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.navigation {
		background-image: url('../../assets/svg/bottom-bar.svg');
		background-position-x: center;
		background-repeat: no-repeat;
		height: $bottombar-height;

		position: fixed;
		bottom: -1px;
		left: 0;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 30;

		@include until($mobile-s) {
			background-position-y: -7px;
		}

		@include from($mobile-s) {
			&::before {
				content: '';
				height: 55px;
				width: 34%;
				position: absolute;
				left: 0;
				bottom: 0px;
				background-color: $black;
			}

			&::after {
				content: '';
				height: 55px;
				width: 34%;
				position: absolute;
				right: 0;
				bottom: 0px;
				background-color: $black;
			}
		}

		ul {
			display: flex;
			width: 90vw;
			justify-content: space-evenly;

			li {
				position: relative;
				list-style: none;
				width: 55px;
				height: 30px;
				z-index: 1;

				&.float {
					a {
						.icon {
							transform: translate(0px, -20px);
						}
					}
				}

				a {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					width: 100%;
					text-align: center;
					font-weight: 500;

					.icon {
						position: relative;
						display: block;
						line-height: 55px;
						font-size: 20px;
						text-align: center;
						transition: 0.5s;
					}
				}
			}
		}
	}
</style>
