import Vue from 'vue';

// initial state
const state = {
	user: '',
	token: null,
	accountType: null,
	recoverEmail: null,
	pw: null,
	socialLogin: false,
	isLoginReset:false,
};

const initial = Object.assign(state);

// getters
const getters = {
	getToken(state, getters) {
		return state.token;
	},

	getUser(state, getters) {
		return state.user;
	},

	getAccountType(state, getters) {
		return state.accountType;
	},
	getRecoverEmail(state, getters) {
		return state.recoverEmail;
	},
	getPw(state, getters) {
		return state.pw;
	},
	getSocialLogin(state, getters) {
		return state.socialLogin;
	},
	getIsLoginReset(state, getters) {
		return state.isLoginReset;
	},
};

// mutations
const mutations = {
	setToken(state, token) {
		state.token = token;
	},

	resetStore(s) {
		s = Object.assign(initial);
	},

	addUser(state, user) {
		Vue.set(state, 'user', user);
	},

	setAccountType(state, type) {
		state.accountType = type;
	},

	setRecoverEmail(state, email) {
		state.recoverEmail = email;
	},

	updateStore(state, store) {
		state.user['store'] = store;
	},

	setPw(state, val) {
		state.pw = val;
	},
	setSocialLogin(state, val) {
		state.socialLogin = val;
	},
	setIsLoginReset(state, bol) {
		state.isLoginReset = bol;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
