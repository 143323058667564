import store from '@/store/index';

export default {
	computed: {
		IS_MOBILE() {
			return store.getters['mobile/IS_MOBILE'];
		},
		IS_MOBILE_NATIVE() {
			return store.getters['mobile/IS_MOBILE_NATIVE'];
		},
	},
	methods: {
		SET_IS_MOBILE(bol) {
			store.commit('mobile/SET_IS_MOBILE', bol);
		},
		SET_IS_MOBILE_NATIVE(bol) {
			store.commit('mobile/SET_IS_MOBILE_NATIVE', bol);
		},
	},
};
