import parts from '@/mixins/modules/shared/parts';
import { VueInstance } from '@/main.js';

export default {
	methods: {
		goToPage(location) {
			if (typeof location === 'string' || location instanceof String) {
				if (VueInstance.$route.name !== location) {
					parts.methods.setMenuVisible(false);
					parts.methods.setNotificationsVisible(false);
					VueInstance.$router.push({ name: location });
				}
			} else {
				if (VueInstance.$route.name !== location.name) {
					parts.methods.setMenuVisible(false);
					parts.methods.setNotificationsVisible(false);
					VueInstance.$router.push(location);
				}
			}
			parts.methods.addSidebarStatus(false);
		},

		scrollTop() {
			window.scrollTo(0, 0);
		},
	},
};
