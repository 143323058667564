<template>
	<div class="footer">
		<div class="container">
			<hr />
			<div class="level mt-6">
				<div class="level-left">
					<div class="level-item">
						<figure class="image is-32x32">
							<img src="@/assets/logo.png" alt="" />
						</figure>
					</div>
				</div>
				<div class="level-right">
					<div class="level-item mx-3">
						<b-icon icon="instagram" @click.native="openInstagram" class="is-clickable" type="is-grey"></b-icon>
					</div>
					<div class="level-item mx-3">
						<b-icon pack="rt" icon="facebook" @click.native="openFacebook" size="is-size-5" class="is-clickable" type="is-grey"></b-icon>
					</div>
					<div class="level-item ml-3">
						<b-icon pack="rt" icon="twitter" @click.native="openTwitter" size="is-size-5" class="is-clickable" type="is-grey"></b-icon>
					</div>
				</div>
			</div>
			<div class="level mb-8">
				<div class="level-left">
					<div class="level-item">
						<p class="has-text-grey-darker is-size-5">© 2022</p>
					</div>
				</div>
				<!-- <div class="level-right has-text-grey-darker is-size-5">
					<div class="level-item mx-3">
						<p>{{t.privacy}}</p>
					</div>
					<div class="level-item mx-3">
						<p>{{t.accessibility}}</p>
					</div>
					<div class="level-item ml-3">
						<p class="is-clickable" @click="openTerms">{{t.terms}}</p>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				t: this.$t(`components.Desktop.Parts.Footer`),
			};
		},
		methods: {
			openInstagram() {
				window.open('https://www.instagram.com/roottecnology/', '_blank');
			},
			openFacebook() {
				window.open('https://www.facebook.com/theroottechnology', '_blank');
			},
			openTwitter() {
				window.open('https://twitter.com/progressiveroot', '_blank');
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.footer {
		height: $footer-height;
		position: sticky;
		bottom: 0;
		width: 100%;
		top: 100%;
	}

	hr {
		margin: 0;
	}
</style>
