import parts from '../../mixins/modules/shared/parts';

export default function (to, from, next) {
	const { hideBottomBar, bottomBarSelected, hideTopBarMobile, hideTopBarDesktop, hideFooter } = to.meta;

	console.log(to.meta);
	parts.methods.setIsBottomBarVisible(!hideBottomBar ? true : false);
	parts.methods.setBottomBarSelected(bottomBarSelected);
	parts.methods.setIsTopBarVisibleMobile(!hideTopBarMobile ? true : false);
	parts.methods.setIsTopBarVisibleDesktop(!hideTopBarDesktop ? true : false);
	parts.methods.setIsFooterVisible(!hideFooter ? true : false);
}
