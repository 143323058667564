import store from '@/store/index';

export default {
	computed: {
		getBookmark() {
			return store.getters['bookmark/getBookmark'];
		},
		
		
	},
	methods: {
		setBookmark(num) {
			store.commit('bookmark/setBookmark', num);
		},
	
		
	},
};