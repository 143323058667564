import store from '@/store/index';
import router from '@/router/index';

export default function () {
	router.beforeEach((to, from, next) => {
		const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

		if (requiresAuth) {
			console.log(1);
			let token = store.getters['auth/getToken'];
			if (token) {
				if (to.name === 'Homepage' && store.getters['auth/getUser'].isRegistered === false) {
					if (this.IS_MOBILE) {
						next({ name: 'MyPreferences' });
					} else {
						store.commit('desktopLoginStep/setStep', 3);
						next({ name: 'Home' });
					}
				} else next();
			} else {
				store.commit('auth/resetStore');
				store.commit('auth/setToken', null);
				store.commit('auth/addUser', null);
				store.commit('auth/setSocialLogin', false);
				store.commit('auth/setAccountType', null);
				store.commit('filter/setCategory', []);
				store.commit('filter/setSize', []);
				store.commit('filter/setNumbers', []);
				store.commit('filter/setColor', []);
				store.commit('filter/setBrand', []);
				store.commit('filter/setOnlySale', null);
				store.commit('filter/setMainCategory', []);
				store.commit('filter/setFilterString', null);
				store.commit('cart/setCart', null);
				store.commit('shipping/setCameFromCard', false);
	
				localStorage.removeItem('ROOT');
				router.push({ name: 'Homepage' });
			}
		} else {
			next();
		}
		const onlyNoAuth = to.matched.some((record) => record.meta.onlyNoAuth);

		if (onlyNoAuth) {
			let token = store.getters['auth/getToken'];
			let user = store.getters['auth/getUser'];
			console.log(token);
			console.log(user);
			if (!token) {
				next();
			} else {
				if (to.name === 'Homepage' && store.getters['auth/getUser'].isRegistered === false) {
					if (this.IS_MOBILE) {
						next({ name: 'MyPreferences' });
					} else {
						store.commit('desktopLoginStep/setStep', 4);
						next({ name: 'Home' });
					}
				} else next({ name: 'Homepage' });
			}
		} else {
			next();
		}
	});
}
