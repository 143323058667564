import store from '@/store/index';

export default {
	computed: {
		getShippingType() {
			return store.getters['shipping/getShippingType'];
		},
		getAddress() {
			return store.getters['shipping/getAddress'];
		},
		getZipCode() {
			return store.getters['shipping/getZipCode'];
		},
		getCity() {
			return store.getters['shipping/getCity'];
		},
		getCountry() {
			return store.getters['shipping/getCountry'];
		},
		getBillingAddress() {
			return store.getters['shipping/getBillingAddress'];
		},
		getBillingZipCode() {
			return store.getters['shipping/getBillingZipCode'];
		},
		getBillingCity() {
			return store.getters['shipping/getBillingCity'];
		},
		getBillingCountry() {
			return store.getters['shipping/getBillingCountry'];
		},
		getExtraDiscount() {
			return store.getters['shipping/getExtraDiscount'];
		},
		getDiscountMoney() {
			return store.getters['shipping/getDiscountMoney'];
		},
		getDiscountCode() {
			return store.getters['shipping/getDiscountCode'];
		},
		getPickupId() {
			return store.getters['shipping/getPickupId'];
		},
		getUseDeliveryAddress() {
			return store.getters['shipping/getUseDeliveryAddress'];
		},
		getCameFromCard() {
			return store.getters['shipping/getCameFromCard'];
		},
		getPaymentType() {
			return store.getters['shipping/getPaymentType'];
		},
		getNif() {
			return store.getters['shipping/getNif'];
		},
		getCompany() {
			return store.getters['shipping/getCompany'];
		},
		getPhone() {
			return store.getters['shipping/getPhone'];
		},
	},
	methods: {
		setShippingType(payload) {
			store.commit('shipping/setShippingType', payload);
		},
		setAddress(payload) {
			store.commit('shipping/setAddress', payload);
		},
		setZipCode(payload) {
			store.commit('shipping/setZipCode', payload);
		},
		setCity(payload) {
			store.commit('shipping/setCity', payload);
		},
		setCountry(payload) {
			store.commit('shipping/setCountry', payload);
		},
		setBillingAddress(payload) {
			store.commit('shipping/setBillingAddress', payload);
		},
		setBillingZipCode(payload) {
			store.commit('shipping/setBillingZipCode', payload);
		},
		setBillingCity(payload) {
			store.commit('shipping/setBillingCity', payload);
		},
		setBillingCountry(payload) {
			store.commit('shipping/setBillingCountry', payload);
		},
		setExtraDiscount(payload) {
			store.commit('shipping/setExtraDiscount', payload);
		},
		setDiscountCode(payload) {
			store.commit('shipping/setDiscountCode', payload);
		},

		setPickupId(payload) {
			store.commit('shipping/setPickUpId', payload);
		},
		setUseDeliveryAddress(payload) {
			store.commit('shipping/setUseDeliveryAddress', payload);
		},
		setCameFromCard(payload) {
			store.commit('shipping/setCameFromCard', payload);
		},
		setPaymentType(payload) {
			store.commit('shipping/setPaymentType', payload);
		},
		setNif(payload) {
			store.commit('shipping/setNif', payload);
		},
		setCompany(payload) {
			store.commit('shipping/setCompany', payload);
		},
		setPhone(payload) {
			store.commit('shipping/setPhone', payload);
		},
	},
};
