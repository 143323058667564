import { Validator } from 'vee-validate';
import validator from 'validator';

Validator.extend('mobile-phone', {
	getMessage: () => 'Número de telefone inválido',
	validate: (value) => {
		if (value[0].toString() != 2) {
			return validator.isMobilePhone(value.toString(), 'pt-PT');
		} else if (value.length == 9) {
			return true;
		} else return false;
	},
});
