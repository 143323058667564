import store from '@/store/index';

export default {
	computed: {
		getCategorySelected() {
			return store.getters['filter/getCategorySelected'];
		},
		getSizeSelected() {
			return store.getters['filter/getSizeSelected'];
		},
		getNumbers() {
			return store.getters['filter/getNumbers'];
		},
		getColorSelected() {
			return store.getters['filter/getColorSelected'];
		},
		getBrandSelected() {
			return store.getters['filter/getBrandSelected'];
		},
		getOnlySale() {
			return store.getters['filter/getOnlySale'];
		},
		getMainCategorySelected() {
			return store.getters['filter/getMainCategorySelected'];
		},
		getFilterString() {
			return store.getters['filter/getFilterString'];
		},
		getFilterStores() {
			return store.getters['filter/getFilterStores'];
		},
		getSavedFilterString() {
			return store.getters['filter/getSavedFilterString'];
		},
		getResetFilter() {
			return store.getters['filter/getResetFilter'];
		},
		getSavedFilters() {
			return store.getters['filter/getSavedFilters'];
		},
		getSavedCategorySelected() {
			return store.getters['filter/getSavedCategorySelected'];
		},
		getSavedSizeSelected() {
			return store.getters['filter/getSavedSizeSelected'];
		},
		getSavedNumbers() {
			return store.getters['filter/getSavedNumbers'];
		},
		getSavedColorSelected() {
			return store.getters['filter/getSavedColorSelected'];
		},
		getSavedBrandSelected() {
			return store.getters['filter/getSavedBrandSelected'];
		},
		getSavedOnlySale() {
			return store.getters['filter/getSavedOnlySale'];
		},
		getSavedStores() {
			return store.getters['filter/getSavedStores'];
		},
	},
	methods: {
		setCategorySelected(id) {
			store.commit('filter/setCategory', id);
		},
		setSizeSelected(id) {
			store.commit('filter/setSize', id);
		},
		setNumbers(num) {
			store.commit('filter/setNumbers', num);
		},
		setColorSelected(id) {
			store.commit('filter/setColor', id);
		},
		setBrandSelected(id) {
			store.commit('filter/setBrand', id);
		},
		setOnlySale(bol) {
			store.commit('filter/setOnlySale', bol);
		},
		setMainCategorySelected(id) {
			store.commit('filter/setMainCategory', id);
		},
		setFilterString(payload) {
			store.commit('filter/setFilterString', payload);
		},
		setFilterStores(id) {
			store.commit('filter/setFilterStores', id);
		},
		setSavedFilterString(payload) {
			store.commit('filter/setSavedFilterString', payload);
		},
		setResetFilter(bol) {
			store.commit('filter/setResetFilter', bol);
		},
		setSavedFilters(bol) {
			store.commit('filter/setSavedFilters', bol);
		},
		setSavedCategorySelected(id) {
			store.commit('filter/setSavedCategorySelected', id);
		},
		setSavedSizeSelected(id) {
			store.commit('filter/setSavedSizeSelected', id);
		},
		setSavedNumbers(num) {
			store.commit('filter/setSavedNumbers', num);
		},
		setSavedColorSelected(id) {
			store.commit('filter/setSavedColorSelected', id);
		},
		setSavedBrandSelected(id) {
			store.commit('filter/setSavedBrandSelected', id);
		},
		setSavedOnlySale(bol) {
			store.commit('filter/setSavedOnlySale', bol);
		},
		setSavedStores(id) {
			store.commit('filter/setSavedStores', id);
		},
	},
};
