<template>
	<div v-if="show" class="menu has-text-dark has-radius">
		<div class="icons-container mt-8" @click="profile">
			<div class="icon-box center-inside pointer"><b-icon pack="rt" icon="avatar" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4 pointer">{{ t.profile }}</span>
		</div>
		<br />

		<div class="icons-container mt-6" @click="editProfile">
			<div class="icon-box center-inside pointer"><b-icon pack="rt" icon="avatar" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4 pointer">{{ t.edit }}</span>
		</div>
		<br v-if="getAccountType == 'authenticated'" />

		<div class="icons-container mt-6" @click="goToPage('Interests')" v-show="getAccountType == 'authenticated'">
			<div class="icon-box center-inside pointer"><b-icon pack="rt" icon="view" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4 pointer">{{ t.interests }}</span>
		</div>
		<br v-if="getAccountType == 'authenticated'" />

		<div class="icons-container mt-6" @click="goToPage('Wallet')" v-if="getAccountType == 'authenticated'">
			<div class="icon-box center-inside pointer"><b-icon pack="rt" icon="wallet" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4 pointer">{{ t.wallet }}</span>
		</div>
		<br />

		<div class="icons-container mt-6" @click="goToPage('ChangePassword')" v-if="!getSocialLogin">
			<div class="icon-box center-inside pointer"><b-icon pack="rt" icon="lock" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4 pointer">{{ t.change }}</span>
		</div>
		<br v-if="!getSocialLogin" />

		<div class="icons-container mt-6" @click="goToPage('Settings')">
			<div class="icon-box center-inside pointer"><b-icon pack="rt" icon="settings" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4 pointer">{{ t.settings }}</span>
		</div>
		<br />

		<div class="icons-container mt-6" @click="goToPage('Transactions')">
			<div class="icon-box center-inside pointer"><b-icon pack="rt" icon="transactions" size="is-size-4" type="is-white"></b-icon></div>
			<span class="ml-4 pointer">{{ t.transactions }}</span>
		</div>

		<p @click="logout" class="has-text-centered has-text-red mt-10 pb-4 pointer">
			<b-icon pack="rt" icon="logout" size="is-size-7" class="pointer" type="is-red"></b-icon>{{ t.logout }}
		</p>
	</div>
</template>

<script>
	import parts from '@/mixins/modules/shared/parts';
	export default {
		mixins: [parts],
		data() {
			return {
				t: this.$t(`components.Parts.SideBar`),
			};
		},
		props: {
			showMenu: {
				type: Boolean,
				default: false,
			},
		},

		computed: {
			getUserType() {
				return this.$store.getters['auth/getAccountType'];
			},

			show() {
				console.log(this.isMenuVisible);
				return this.isMenuVisible;
			},
		},

		methods: {
			logout() {
				this.$socket.disconnect();
				this.onSessionExpired();
				location.reload()
			},

			profile() {
				if (this.getUserType == 'store') {
					this.setMenuVisible(false);
					this.setNotificationsVisible(false);
					this.$router.push({ name: 'StoreProfile', params: { id: this.getUser.store.id } });
				} else {
					parts.methods.setMenuVisible(false);
					parts.methods.setNotificationsVisible(false);
					this.goToPage('UserProfile');
				}
			},

			editProfile() {
				if (this.getUserType == 'store') {
					this.setMenuVisible(false);
					this.setNotificationsVisible(false);
					this.goToPage('EditStoreProfile');
				} else {
					this.setMenuVisible(false);
					this.setNotificationsVisible(false);
					this.goToPage('EditUserProfile');
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.menu {
		width: 300px;
		height: auto;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 25px #00000029;
		border: 1px solid #f5f6f8;
		position: absolute;
		right: 0;
		top: 62px;
		z-index: 31;
	}

	.photo-container {
		display: flex;
		justify-content: center;
	}

	.photo {
		width: 45px;
		height: 45px;
		border-radius: 6px;
		border: solid 1px black;
	}

	.icon-box {
		background-color: $grey-darker;
		width: 42px;
		height: 42px;
		border-radius: 8px;
	}

	.icons-container {
		display: inline-flex;
		padding-left: 30px;
		min-width: 100%;
	}

	span {
		display: inline-flex;
		align-items: center;
	}

	.pointer {
		cursor: pointer;
	}
</style>
