const state = {
	cart: null,
	total: 0,
};

const getters = {
	getCart(state) {
		return state.cart;
	},
	getTotal(state) {
		return state.total;
	},
};

const mutations = {
	setCart(state, payload) {
		state.cart = payload;
	},
	setTotal(state, payload) {
		state.total = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
