import Vue from 'vue';

// initial state
const state = {
	step: 0,
};

const initial = Object.assign(state);

// getters
const getters = {
	getStep(state, getters) {
		return state.step;
	},
};

// mutations
const mutations = {
	setStep(state, step) {
		state.step = step;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
