import { Object as _Object } from '@iotechpis/utils';
_Object;

import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import './assets/scss/app.scss';
import './assets/scss/icons.scss';
import i18n from './i18n';
import HighchartsVue from 'highcharts-vue';
import VueSocketIO from 'vue-socket.io';
import VueNativeNotification from 'vue-native-notification';
import VueClipboard from 'vue-clipboard2';
import Moment from 'moment';

Moment.locale(process.env.VUE_APP_I18N_LOCALE);
Vue.prototype.$moment = Moment;

Vue.use(Buefy, {
	customIconPacks: {
		rt: {
			iconPrefix: 'rt-',
		},
	},
});

Vue.use(VueNativeNotification, {
	// Automatic permission request before
	// showing notification (default: true)
	requestOnNotify: true,
});

Vue.config.productionTip = false;

Vue.mixin({
	methods: {
		$evaluate: (param) => eval('this.' + param),
	},
});

//Charts
Vue.use(HighchartsVue);

//Socket
Vue.use(
	new VueSocketIO({
		debug: true,
		connection: process.env.VUE_APP_API_URL,
		vuex: {
			store,
			actionPrefix: 'SOCKET_',
			mutationPrefix: 'SOCKET_',
		},
	})
);

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

import './mixins/index';
import { initializeApp } from 'firebase/app';

if (process.env.VUE_APP_FIREBASE_API_KEY) {
	initializeApp({
		apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
		authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
		projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
		appId: process.env.VUE_APP_FIREBASE_APP_ID,
		measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
	});
}

import requiresAuth from './utils/requiresAuth';
requiresAuth();

import VeeValidate from 'vee-validate';
import '@/plugins/vee-validate';
Vue.use(VeeValidate);

import vueDebounce from 'vue-debounce';
Vue.use(vueDebounce);

import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
if (process.env.VUE_APP_SENTRY)
	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
		trackComponents: true,
		logErrors: true,
		attachProps: true,
		attachStacktrace: true,
	});

import VueGtag from 'vue-gtag';

if (process.env.NODE_ENV === 'production') {
	Vue.use(
		VueGtag,
		{
			config: {
				id: 'G-CY1FP66MH7',
			},
		},
		router
	);
	console.log = function () {};
}

export const VueInstance = new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
