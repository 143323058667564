const state = {
	bookmark: 0,
};

const getters = {
	getBookmark(state) {
		return state.bookmark;
	},
};

const mutations = {
	setBookmark(state, payload) {
		state.bookmark = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};